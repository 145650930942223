var ESCAPE_KEY_CODE = 27;

// duplicated in mixins/closeOnEscape.js

var onEscape = function onEscape(callback) {
  return function (event) {
    if (event.keyCode === ESCAPE_KEY_CODE) {
      callback(event);
    }
  };
};
export function useCallbackOnEscape(callback) {
  useDocumentListeners({
    keydown: onEscape(callback)
  });
}