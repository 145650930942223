<template>
    <p class="types" :data-context="context">
        <router-link :to="linkRoute(type)" v-for="type in types" :key="type.name" replace :class="linkClasses(type)">
            {{ type.label }}
        </router-link>
    </p>
</template>
<script setup>

const props = defineProps({
    types: { required: true },
    baseRoute: { required: true },
    context: { required: true }
});

const linkRoute = (type) => {
    if (type.selected) {
        return props.baseRoute;
    } else {
        return type.route;
    }
};

const linkClasses = (type) => {
    if (type.selected) {
        return ['router-link-exact-active', 'router-link-active'];
    } else {
        return [];
    }
};

</script>
