// where possible, use useI18n composable instead of this mixin
export default
{
    install(Vue) {
        Vue.mixin(
        {
            computed:
            {
                locale() { return this.$store.state.i18n.locale; }
            },
            methods:
            {
                t(key, scope, options) {
                    return this.$store.getters['i18n/t'](key, scope, options);
                },

                formatDate(isoDateString) {
                    return this.$store.getters['i18n/formatDate'](isoDateString);
                }
            }
        });
    }
};
