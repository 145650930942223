import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.is-array.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.array.some.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/web.dom-collections.iterator.js";
import camelize from "@/utils/camelize.js";
export var usePathologyAndRestorationWizardDetailSteps = function usePathologyAndRestorationWizardDetailSteps(_ref) {
  var selectedType = _ref.selectedType,
    details = _ref.details,
    typeStepIsOpen = _ref.typeStepIsOpen,
    zoneStepAvailable = _ref.zoneStepAvailable,
    zoneStepIsOpen = _ref.zoneStepIsOpen,
    closeZoneStep = _ref.closeZoneStep;
  var _useI18n = useI18n(),
    t = _useI18n.t;
  var detailStepsExist = computed(function () {
    return details.value && (details.value.length > 0 || Object.keys(details.value).length > 0);
  });
  var detailStepsAvailable = computed(function () {
    return detailStepsExist.value && (
    // details always available if there are no zones for this type
    !zoneStepAvailable.value ||
    // details should appear if zones are available and zone step is closed
    zoneStepAvailable.value && !zoneStepIsOpen.value ||
    // details should still be visible if they have some values already set and zone step gets opened again
    zoneStepAvailable.value && zoneStepIsOpen.value && anyDetailsHaveSetValues.value);
  });
  var anyDetailsHaveSetValues = computed(function () {
    if (!detailStepsExist.value) {
      return false;
    }
    return Object.keys(details.value).some(function (detailName) {
      return detailHasValue(detailName);
    });
  });
  var detailHasValue = function detailHasValue(detailName) {
    if (!details.value[detailName]) {
      return false;
    }
    var values = details.value[detailName].values;
    if (!values) {
      return false;
    }
    if (Array.isArray(values) && values.filter(function (x) {
      return x !== undefined && x !== null;
    }).length < 1) {
      return false;
    }
    return true;
  };
  var availableDetailNames = computed(function () {
    return detailStepsAvailable.value ? Object.keys(details.value) : [];
  });
  var displayableDetailSteps = computed(function () {
    if (typeStepIsOpen.value) {
      return {};
    }
    var displayableDetailNames = availableDetailNames.value.filter(function (detailName) {
      return detailStepIsDisplayable(detailName);
    });
    return displayableDetailNames.reduce(function (obj, detailName) {
      obj[detailName] = details.value[detailName];
      return obj;
    }, {});
  });
  var detailStepIsDisplayable = function detailStepIsDisplayable(detailName) {
    if (detailHasValue(detailName) || detailStepIsOpen(detailName)) {
      return true;
    }
    // this prevents displaying the last detail step if it is closed and has no value.
    // this may happen if it has no value and an earlier step gets re-opened
    return false;
  };
  var openedDetailName = ref(null);
  var openedDetailValue = computed(function () {
    var name = openedDetailName.value;
    if (!name) {
      return null;
    }
    if (!details.value[name]) {
      return null;
    }
    return details.value[name].values;
  });
  var openedDetailState = computed(function () {
    return {
      name: openedDetailName.value,
      values: openedDetailValue.value
    };
  });
  var detailStepIsOpen = function detailStepIsOpen(detailName) {
    return openedDetailName.value === detailName;
  };
  var openDetailStep = function openDetailStep(detailName) {
    openedDetailName.value = detailName;
    closeZoneStep();
  };
  var closeDetailStep = function closeDetailStep(detailName) {
    if (openedDetailName.value === detailName) {
      openedDetailName.value = null;
    }
  };
  var closeOpenedDetailStep = function closeOpenedDetailStep() {
    closeDetailStep(openedDetailName.value);
  };
  var detailLabel = function detailLabel(detailName, detail) {
    if (detail.labelKey) {
      return t(detail.labelKey);
    }
    return t(detailName, "pathology." + camelize(selectedType.value.name));
  };
  var detailText = function detailText(detail) {
    return detail.values.filter(function (value) {
      return value === 0 || !!value;
    }).map(function (value) {
      return detail.options.find(function (option) {
        return option.value === value;
      }).label;
    }).join(", ");
  };
  watch(openedDetailState, function (newState, previousState) {
    var detailName = newState.name;
    var detailNameChanged = detailName !== previousState.name;
    var valueChanged = newState.values !== previousState.values;
    // if selected detail itself changed, do nothing
    if (detailNameChanged) {
      return;
    }
    if (valueChanged) {
      if (detailHasValue(detailName) && details.value[detailName] && !details.value[detailName].multiple) {
        // close detail if value was set,
        // but ignore details with multiple selectable options.
        // luckily, there is only one such case (tooth wear zones), which are the last step for tooth wear
        // and thus can remain open
        closeDetailStep(detailName);
      }
    }
  });
  watch(selectedType, function () {
    closeOpenedDetailStep();
  });
  watch(zoneStepIsOpen, function (value) {
    if (value) {
      closeOpenedDetailStep();
    }
  });
  watch(availableDetailNames, function (names) {
    if (openedDetailName.value && !names.includes(openedDetailName.value)) {
      closeOpenedDetailStep();
    }
    if (zoneStepIsOpen.value) {
      return closeOpenedDetailStep();
    }
    // automatically open first detail that has a blank value
    var firstDetailNameWithNoValue = names.find(function (name) {
      return !detailHasValue(name);
    });
    if (firstDetailNameWithNoValue) {
      openDetailStep(firstDetailNameWithNoValue);
    }
  });
  return {
    detailStepsExist: detailStepsExist,
    detailStepsAvailable: detailStepsAvailable,
    anyDetailsHaveSetValues: anyDetailsHaveSetValues,
    displayableDetailSteps: displayableDetailSteps,
    detailStepIsOpen: detailStepIsOpen,
    openDetailStep: openDetailStep,
    detailLabel: detailLabel,
    detailText: detailText
  };
};