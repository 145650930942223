<template>
    <a
        class="trigger"
        v-bind="attributes"
        v-on:click.prevent="trigger"
    >
        <slot />
    </a>
</template>
<script>
import { CHART_MODES } from '@/config/teeth.js';

export default {
    props: {
        tooth: { required: true },
        mode: { required: true, validator: value => Object.values(CHART_MODES).includes(value) },
    },
    computed: {
        attributes() {
            if (this.mode == CHART_MODES.QUICKSELECT) {
                return {
                    role: 'checkbox',
                    tabindex: '0',
                    'aria-checked': (this.isToothSelected) ? 'true' : 'false',
                    'data-erupted': (this.isToothErupted) ? 'true' : 'false'
                };
            } else if (this.mode == CHART_MODES.REPORT || !this.isToothErupted) {
                return {
                    role: 'none'
                };
            } else {
                return {
                    href: this.$router.resolve(this.route).href
                };
            }
        },

        route() {
            let routeName = undefined;
            let routeParams = {};

            if (
                [
                    CHART_MODES.PERIODONTAL_PROBING,
                    CHART_MODES.PATHOLOGY,
                    CHART_MODES.RESTORATION
                ].includes(this.mode)
            ) {
                routeName = this.chartRouteName(this.mode, this.$route.meta["mouth-view"]);
                routeParams = { zone_name: undefined };
            }

            return this.toothRoute(this.tooth, routeName, routeParams);
        },

        isToothSelected() {
            return this.$store.getters['patient/teeth/quickselect/isToothNumberSelected'](this.tooth.number);
        },
        isToothMissing() {
            return this.$store.getters['patient/teeth/state/isToothMissing'](this.tooth.number);
        },
        isToothErupted() {
            return this.$store.getters['patient/teeth/state/isToothErupted'](this.tooth.number);
        },

    },

    methods: {
        trigger() {
            if (this.mode == CHART_MODES.QUICKSELECT) {
                if (this.isToothSelected) {
                    this.$store.dispatch('patient/teeth/quickselect/deselectToothNumber', this.tooth.number);
                } else {
                    this.$store.dispatch('patient/teeth/quickselect/selectToothNumber', this.tooth.number);
                }
            } else if (this.mode == CHART_MODES.REPORT || !this.isToothErupted) {
                return;
            } else if (this.mode == CHART_MODES.PERIODONTAL_PROBING || this.mode == CHART_MODES.PATHOLOGY) {
                if (!this.isToothMissing) {
                    this.goToRoute(this.route, { replace: true });
                }
            } else {
                this.goToRoute(this.route);
            }
        }
    }
};
</script>
