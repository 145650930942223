import _slicedToArray from "/home/build/node_modules/@babel/runtime/helpers/esm/slicedToArray.js";
import "core-js/modules/es.object.entries.js";
var registerListener = function registerListener(event, callback) {
  onMounted(function () {
    document.addEventListener(event, callback);
  });
  onUnmounted(function () {
    document.removeEventListener(event, callback);
  });
};
var listenOnDocument = function listenOnDocument(documentListeners) {
  for (var _i = 0, _Object$entries = Object.entries(documentListeners); _i < _Object$entries.length; _i++) {
    var _Object$entries$_i = _slicedToArray(_Object$entries[_i], 2),
      event = _Object$entries$_i[0],
      handler = _Object$entries$_i[1];
    registerListener(event, handler);
  }
};
export function useDocumentListeners(listeners) {
  listenOnDocument(listeners);
}