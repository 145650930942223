import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.error.to-string.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/web.dom-collections.iterator.js";
import camelize from '@/utils/camelize.js';
export var usePathologyAndRestorationEditing = function usePathologyAndRestorationEditing(_ref) {
  var mode = _ref.mode,
    tooth = _ref.tooth,
    getTypeRoute = _ref.getTypeRoute,
    type = _ref.type;
  var route = useRoute();
  var store = useStore();
  var _useI18n = useI18n(),
    t = _useI18n.t;

  // store scopes depending on mode and type
  var storeModuleName = computed(function () {
    // store names for pathology and restoration modules
    // match the chart mode names in chart view
    // and view names in single tooth views
    return mode.value;
  });
  var otherStoreModuleName = computed(function () {
    return ['pathology', 'restoration'].filter(function (mode) {
      return mode != storeModuleName.value;
    })[0];
  });
  var storeScope = computed(function () {
    return 'patient/teeth/state/' + storeModuleName.value;
  });
  var otherStoreScope = computed(function () {
    return 'patient/teeth/state/' + otherStoreModuleName.value;
  });
  var selectedTypeStoreScope = computed(function () {
    return storeScope.value + '/' + camelize(selectedType.value.name);
  });
  var _useNavigation = useNavigation(),
    goToRoute = _useNavigation.goToRoute;
  var toothNumber = computed(function () {
    return tooth.value.number;
  });

  // type selection

  // for usage in view-level components (which call the initializePathologyAndRestorationEditing() function),
  // type is used internally here as a ref
  // and is managed by this composable based on changing route params (e.g., decay, filling)

  // for usage in deeper sub-components, type gets passed into this composable as an argument
  // and is not changed internally
  var internalTypeName = ref(undefined);
  var selectedTypeName = computed({
    get: function get() {
      if (type) {
        return type.value ? type.value.name : undefined;
      }
      return internalTypeName.value;
    },
    set: function set(typeName) {
      if (type) {
        throw new Error("Cannot modify selected type internally when type is passed in.");
      }
      internalTypeName.value = typeName;
    }
  });
  var selectedType = computed(function () {
    return store.getters[storeScope.value + '/type'](selectedTypeName.value, toothNumber.value);
  });
  var routeTypeName = computed(function () {
    return route.params.type_name;
  });
  var baseRoute = computed(function () {
    return getTypeRoute(undefined);
  });
  var selectRouteType = function selectRouteType() {
    // this corrects unrecognized type names in URL (reroutes to no type)
    if (store.getters[storeScope.value + '/isTypeNameValid'](routeTypeName.value, toothNumber.value)) {
      selectedTypeName.value = routeTypeName.value;
    } else if (routeTypeName.value) {
      goToRoute(baseRoute.value, {
        replace: true
      });
    } else {
      selectedTypeName.value = undefined;
    }
  };
  watch(selectedTypeName, function (typeName) {
    store.dispatch(storeScope.value + '/setType', {
      toothNumber: toothNumber.value,
      typeName: typeName
    });
  });
  var types = computed(function () {
    // available types based on mode and tooth number
    return store.getters[storeScope.value + '/types'](toothNumber.value).map(function (type) {
      return Object.assign({}, type, {
        route: getTypeRoute(type.name),
        selected: type.name == selectedTypeName.value
      });
    });
  });
  var details = computed({
    get: function get() {
      if (selectedType.value) {
        return store.getters[selectedTypeStoreScope.value + '/details'](toothNumber.value);
      } else {
        return [];
      }
    },
    set: function set(_ref2) {
      var detailName = _ref2.detailName,
        values = _ref2.values;
      store.dispatch(selectedTypeStoreScope.value + '/setDetail', {
        toothNumber: toothNumber.value,
        detailName: detailName,
        values: values
      });
    }
  });
  var zones = computed({
    get: function get() {
      var type = selectedType.value;
      if (type) {
        var definedZoneNames = store.getters[selectedTypeStoreScope.value + '/definedZoneNames'](toothNumber.value);
        var selectedZoneNames = store.getters[selectedTypeStoreScope.value + '/selectedZoneNames'](toothNumber.value);
        var forcedZoneNames = type.forced;
        return definedZoneNames.filter(function (zoneName) {
          return !forcedZoneNames.includes(zoneName);
        }).map(function (zoneName) {
          return {
            name: zoneName,
            selected: selectedZoneNames.includes(zoneName)
          };
        });
      } else {
        return [];
      }
    },
    set: function set(selectedZoneNames) {
      return store.dispatch(selectedTypeStoreScope.value + '/setSelectedZoneNames', {
        toothNumber: toothNumber.value,
        selectedZoneNames: selectedZoneNames
      });
    }
  });

  // tooth editing
  var waiterName = function waiterName(actionName) {
    return store.getters[storeScope.value + '/waiterName'](actionName);
  };
  var startEditingTooth = function startEditingTooth() {
    store.dispatch(otherStoreScope.value + '/endEditingTooth');
    store.dispatch(storeScope.value + '/startEditingTooth', {
      toothNumber: toothNumber.value
    });
  };
  var endEditingTooth = function endEditingTooth() {
    store.dispatch(storeScope.value + '/endEditingTooth');
  };
  var actionsEnabled = computed(function () {
    return selectedType.value && store.getters[storeScope.value + '/areActionsEnabled']();
  });
  var actionLabel = function actionLabel(actionName) {
    return t(actionName, 'dentalDetailsVC.dental');
  };
  var performAction = function performAction(name, afterActionRoute) {
    store.dispatch(storeScope.value + '/performAction', {
      name: name
    }).then(function () {
      goToRoute(afterActionRoute.value);
    });
  };
  var initializePathologyAndRestorationEditing = function initializePathologyAndRestorationEditing() {
    watch(toothNumber, function () {
      selectRouteType();
      startEditingTooth();
    });
    watch(mode, function () {
      selectRouteType();
      startEditingTooth();
    });
    watch(routeTypeName, function () {
      selectRouteType();
    });
    onBeforeUnmount(function () {
      endEditingTooth();
    });
    selectRouteType();
    startEditingTooth();
  };
  return {
    baseRoute: baseRoute,
    toothNumber: toothNumber,
    storeScope: storeScope,
    types: types,
    details: details,
    zones: zones,
    selectedTypeName: selectedTypeName,
    selectedType: selectedType,
    selectedTypeStoreScope: selectedTypeStoreScope,
    actionsEnabled: actionsEnabled,
    actionLabel: actionLabel,
    waiterName: waiterName,
    performAction: performAction,
    initializePathologyAndRestorationEditing: initializePathologyAndRestorationEditing
  };
};