import WizardStep from '@/components/patient/chart/pathology-and-restoration/wizard-step.vue';
import TypeSelector from '@/components/patient/tooth/pathology-and-restoration/type-selector.vue';
import ZoneSelector from '@/components/patient/tooth/pathology-and-restoration/zone-selector.vue';
import DetailOptions from '@/components/patient/pathology-and-restoration/detail-options.vue';
import { PATHOLOGY_AND_RESTORATION_ACTIONS } from '@/config/pathologies-and-restorations.js';
export default {
  __name: 'wizard',
  props: {
    tooth: {
      required: true
    },
    mode: {
      required: true
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var mode = computed(function () {
      return props.mode;
    });
    var tooth = computed(function () {
      return props.tooth;
    });
    var route = useRoute();
    var _useNavigation = useNavigation(),
      chartRoute = _useNavigation.chartRoute;
    var getTypeRoute = function getTypeRoute(typeName) {
      return chartRoute(mode.value, route.meta['mouth-view'], {
        tooth_slug: tooth.value.slug,
        type_name: typeName
      });
    };
    var _usePathologyAndResto = usePathologyAndRestorationEditing({
        mode: mode,
        tooth: tooth,
        getTypeRoute: getTypeRoute
      }),
      baseRoute = _usePathologyAndResto.baseRoute,
      types = _usePathologyAndResto.types,
      zones = _usePathologyAndResto.zones,
      details = _usePathologyAndResto.details,
      selectedType = _usePathologyAndResto.selectedType,
      actionsEnabled = _usePathologyAndResto.actionsEnabled,
      actionLabel = _usePathologyAndResto.actionLabel,
      waiterName = _usePathologyAndResto.waiterName,
      performAction = _usePathologyAndResto.performAction,
      initializePathologyAndRestorationEditing = _usePathologyAndResto.initializePathologyAndRestorationEditing;
    initializePathologyAndRestorationEditing();

    // type step
    var _usePathologyAndResto2 = usePathologyAndRestorationWizardTypeStep({
        mode: mode,
        selectedType: selectedType,
        types: types
      }),
      typeStepIsOpen = _usePathologyAndResto2.typeStepIsOpen,
      openTypeStep = _usePathologyAndResto2.openTypeStep,
      typeStepLabel = _usePathologyAndResto2.typeStepLabel,
      typeStepText = _usePathologyAndResto2.typeStepText,
      clonedTypes = _usePathologyAndResto2.clonedTypes;

    // zone step

    // zone and detail steps ar cross-dependent, so some reactive detail state values need to be passed to the zone step.
    // these two values cannot be used directly and need to be renamed here by wrapping in computed(),
    // because they become reactive only further down in the script when calling the detail steps composable.
    var detailStepsExistForZoneStep = computed(function () {
      return detailStepsExist.value;
    });
    var anyDetailsHaveSetValuesForZoneStep = computed(function () {
      return anyDetailsHaveSetValues.value;
    });
    var _usePathologyAndResto3 = usePathologyAndRestorationWizardZoneStep({
        selectedType: selectedType,
        zones: zones,
        typeStepIsOpen: typeStepIsOpen,
        detailStepsExistForZoneStep: detailStepsExistForZoneStep,
        anyDetailsHaveSetValuesForZoneStep: anyDetailsHaveSetValuesForZoneStep
      }),
      zoneStepAvailable = _usePathologyAndResto3.zoneStepAvailable,
      zoneStepIsOpen = _usePathologyAndResto3.zoneStepIsOpen,
      openZoneStep = _usePathologyAndResto3.openZoneStep,
      closeZoneStep = _usePathologyAndResto3.closeZoneStep,
      zoneStepLabel = _usePathologyAndResto3.zoneStepLabel,
      zoneStepText = _usePathologyAndResto3.zoneStepText,
      shouldShowZoneStepCloseButton = _usePathologyAndResto3.shouldShowZoneStepCloseButton,
      zoneStepCloseButtonText = _usePathologyAndResto3.zoneStepCloseButtonText;

    // detail steps
    var _usePathologyAndResto4 = usePathologyAndRestorationWizardDetailSteps({
        selectedType: selectedType,
        details: details,
        typeStepIsOpen: typeStepIsOpen,
        zoneStepAvailable: zoneStepAvailable,
        zoneStepIsOpen: zoneStepIsOpen,
        closeZoneStep: closeZoneStep
      }),
      detailStepsExist = _usePathologyAndResto4.detailStepsExist,
      detailStepsAvailable = _usePathologyAndResto4.detailStepsAvailable,
      anyDetailsHaveSetValues = _usePathologyAndResto4.anyDetailsHaveSetValues,
      displayableDetailSteps = _usePathologyAndResto4.displayableDetailSteps,
      detailStepIsOpen = _usePathologyAndResto4.detailStepIsOpen,
      openDetailStep = _usePathologyAndResto4.openDetailStep,
      detailLabel = _usePathologyAndResto4.detailLabel,
      detailText = _usePathologyAndResto4.detailText;

    // saving
    var actionName = PATHOLOGY_AND_RESTORATION_ACTIONS.SAVE;
    var afterActionRoute = computed(function () {
      return chartRoute(mode.value, route.meta['mouth-view'], {
        tooth_slug: undefined,
        type_name: undefined
      });
    });
    var save = function save() {
      performAction(actionName, afterActionRoute);
    };
    return {
      __sfc: true,
      props: props,
      mode: mode,
      tooth: tooth,
      route: route,
      chartRoute: chartRoute,
      getTypeRoute: getTypeRoute,
      baseRoute: baseRoute,
      types: types,
      zones: zones,
      details: details,
      selectedType: selectedType,
      actionsEnabled: actionsEnabled,
      actionLabel: actionLabel,
      waiterName: waiterName,
      performAction: performAction,
      initializePathologyAndRestorationEditing: initializePathologyAndRestorationEditing,
      typeStepIsOpen: typeStepIsOpen,
      openTypeStep: openTypeStep,
      typeStepLabel: typeStepLabel,
      typeStepText: typeStepText,
      clonedTypes: clonedTypes,
      detailStepsExistForZoneStep: detailStepsExistForZoneStep,
      anyDetailsHaveSetValuesForZoneStep: anyDetailsHaveSetValuesForZoneStep,
      zoneStepAvailable: zoneStepAvailable,
      zoneStepIsOpen: zoneStepIsOpen,
      openZoneStep: openZoneStep,
      closeZoneStep: closeZoneStep,
      zoneStepLabel: zoneStepLabel,
      zoneStepText: zoneStepText,
      shouldShowZoneStepCloseButton: shouldShowZoneStepCloseButton,
      zoneStepCloseButtonText: zoneStepCloseButtonText,
      detailStepsExist: detailStepsExist,
      detailStepsAvailable: detailStepsAvailable,
      anyDetailsHaveSetValues: anyDetailsHaveSetValues,
      displayableDetailSteps: displayableDetailSteps,
      detailStepIsOpen: detailStepIsOpen,
      openDetailStep: openDetailStep,
      detailLabel: detailLabel,
      detailText: detailText,
      actionName: actionName,
      afterActionRoute: afterActionRoute,
      save: save,
      WizardStep: WizardStep,
      TypeSelector: TypeSelector,
      ZoneSelector: ZoneSelector,
      DetailOptions: DetailOptions
    };
  }
};