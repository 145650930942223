import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
export var usePathologyAndRestorationWizardZoneStep = function usePathologyAndRestorationWizardZoneStep(_ref) {
  var selectedType = _ref.selectedType,
    zones = _ref.zones,
    typeStepIsOpen = _ref.typeStepIsOpen,
    detailStepsExistForZoneStep = _ref.detailStepsExistForZoneStep,
    anyDetailsHaveSetValuesForZoneStep = _ref.anyDetailsHaveSetValuesForZoneStep;
  var detailStepsExist = computed(function () {
    return detailStepsExistForZoneStep.value;
  });
  var anyDetailsHaveSetValues = computed(function () {
    return anyDetailsHaveSetValuesForZoneStep.value;
  });
  var _useI18n = useI18n(),
    t = _useI18n.t;
  var store = useStore();
  var zonesExistForSelectedType = computed(function () {
    return zones.value.length > 0;
  });
  var zoneStepAvailable = computed(function () {
    return zonesExistForSelectedType.value && !typeStepIsOpen.value;
  });
  var selectedZones = computed(function () {
    return zones.value.filter(function (zone) {
      return zone.selected;
    });
  });
  var anyZonesSelected = computed(function () {
    return selectedZones.value.length > 0;
  });
  var zoneStepIsOpen = ref(zoneStepAvailable.value && !anyZonesSelected.value);
  var openZoneStep = function openZoneStep() {
    zoneStepIsOpen.value = true;
  };
  var closeZoneStep = function closeZoneStep() {
    zoneStepIsOpen.value = false;
  };
  var reinitializeZoneStep = function reinitializeZoneStep() {
    if (zoneStepAvailable.value && !anyZonesSelected.value && !anyDetailsHaveSetValues.value) {
      openZoneStep();
    }
  };
  watch(zoneStepAvailable, function (value) {
    if (value && selectedType.value && !anyZonesSelected.value && !anyDetailsHaveSetValues.value) {
      openZoneStep();
    } else {
      closeZoneStep();
    }
  });
  watch(selectedType, function (value) {
    if (value) {
      reinitializeZoneStep();
    }
  });
  watch(typeStepIsOpen, function (value) {
    if (value) {
      closeZoneStep();
    }
  });
  var zoneStepLabel = computed(function () {
    return t("surfaces", "dental");
  });
  var zoneStepText = computed(function () {
    if (!anyZonesSelected.value) {
      return t("Not specified", "pathologyAndRestoration");
    }
    var labels = selectedZones.value.map(function (zone) {
      return store.getters['patient/teeth/zoneLabel'](zone.name);
    });
    return labels.join(", ");
  });
  var shouldShowZoneStepCloseButton = computed(function () {
    return zoneStepIsOpen.value && detailStepsExist.value;
  });
  var zoneStepCloseButtonText = computed(function () {
    return anyDetailsHaveSetValues.value ? t("Close surfaces", "pathologyAndRestoration") : t("Add details", "pathologyAndRestoration");
  });
  return {
    zoneStepAvailable: zoneStepAvailable,
    zoneStepIsOpen: zoneStepIsOpen,
    openZoneStep: openZoneStep,
    closeZoneStep: closeZoneStep,
    zoneStepLabel: zoneStepLabel,
    zoneStepText: zoneStepText,
    shouldShowZoneStepCloseButton: shouldShowZoneStepCloseButton,
    zoneStepCloseButtonText: zoneStepCloseButtonText
  };
};