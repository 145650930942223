<template>
    <component :is="component" v-bind="attributes">
        <svg-image
            :name="type"
            :title="t(type, 'common')"
            width="21"
            height="21"
        />
    </component>
</template>

<script>
const allowedTypes = ['back', 'close'];

export default {
    props: {
        type: {
            type: String,
            default: 'back',
            validator: value => allowedTypes.includes(value)
        },
        to: {
            required: false,
        }
    },
    computed: {
        targetRoute() {
            // support for both passing the backRoute as "to" prop and the legacy global mixin version via this.backRoute
            return (this.to) ? this.to : this.backRoute;
        },
        targetRouteIsExternalUrl() {
            return typeof this.targetRoute == "string";
        },
        component() {
            return (this.targetRouteIsExternalUrl) ? 'a' : 'router-link';
        },
        attributes() {
            const attributes = { class: this.type };

            if (this.targetRouteIsExternalUrl) {
                attributes.href = this.targetRoute;
            } else {
                attributes.to = this.targetRoute;
            }
            return attributes;
        }
    }
};
</script>
