import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default {
  __name: 'detail-options',
  props: {
    name: {
      required: true
    },
    detail: {
      required: true
    }
  },
  emits: ["input"],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var props = __props;
    var name = computed(function () {
      return props.name;
    });
    var detail = computed(function () {
      return props.detail;
    });
    var inputType = computed(function () {
      return areMultipleValuesAllowed.value ? "checkbox" : "radio";
    });
    var inputId = function inputId(optionValue) {
      return name.value + '-' + optionValue;
    };
    var areMultipleValuesAllowed = computed(function () {
      return detail.value.multiple;
    });
    var selectedOptionValues = computed(function () {
      return detail.value.values;
    });
    var isOptionSelected = function isOptionSelected(optionValue) {
      return selectedOptionValues.value.includes(optionValue);
    };
    var handleInput = function handleInput(optionValue, checked) {
      if (checked) {
        return selectOption(optionValue);
      } else {
        return deselectOptionIfSelected(optionValue);
      }
    };
    var handleClick = function handleClick(optionValue) {
      if (!areMultipleValuesAllowed.value) {
        return deselectOptionIfSelected(optionValue);
      }
    };
    var selectOption = function selectOption(optionValue) {
      if (!isOptionSelected(optionValue)) {
        var values;
        if (areMultipleValuesAllowed.value) {
          // set values to an array of all selected values in definition order
          var selectedValues = selectedOptionValues.value.concat([optionValue]);
          values = detail.value.options.map(function (option) {
            return option.value;
          }).filter(function (value) {
            return selectedValues.includes(value);
          });
        } else {
          values = [optionValue];
        }
        emit('input', {
          detailName: name.value,
          values: values
        });
      }
    };
    var deselectOptionIfSelected = function deselectOptionIfSelected(optionValue) {
      if (isOptionSelected(optionValue)) {
        var values = selectedOptionValues.value.filter(function (value) {
          return value != optionValue;
        });
        emit('input', {
          detailName: name.value,
          values: values
        });
      }
    };
    return {
      __sfc: true,
      props: props,
      emit: emit,
      name: name,
      detail: detail,
      inputType: inputType,
      inputId: inputId,
      areMultipleValuesAllowed: areMultipleValuesAllowed,
      selectedOptionValues: selectedOptionValues,
      isOptionSelected: isOptionSelected,
      handleInput: handleInput,
      handleClick: handleClick,
      selectOption: selectOption,
      deselectOptionIfSelected: deselectOptionIfSelected
    };
  }
};