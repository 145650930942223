export var useI18n = function useI18n() {
  var store = useStore();
  var locale = computed(function () {
    return store.state.i18n.locale;
  });
  var t = function t(key, scope, options) {
    return store.getters['i18n/t'](key, scope, options);
  };
  var formatDate = function formatDate(isoDateString) {
    return store.getters['i18n/formatDate'](isoDateString);
  };
  return {
    locale: locale,
    t: t,
    formatDate: formatDate
  };
};