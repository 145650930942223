import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.object.values.js";
import "core-js/modules/es.string.includes.js";
import PathologyAndRestorationWizard from '@/components/patient/chart/pathology-and-restoration/wizard.vue';
import { CHART_MODES } from '@/config/teeth.js';
export default {
  __name: 'pathology-and-restoration',
  props: {
    teeth: {
      required: true
    },
    mode: {
      required: true,
      validator: function validator(value) {
        return Object.values(CHART_MODES).includes(value);
      }
    },
    tooth: {
      required: true
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var _useNavigation = useNavigation(),
      goToRoute = _useNavigation.goToRoute,
      backRoute = _useNavigation.backRoute;
    var close = function close() {
      goToRoute(backRoute.value);
    };
    useCallbackOnEscape(close);
    var mode = computed(function () {
      return props.mode;
    });
    var tooth = computed(function () {
      return props.tooth;
    });
    return {
      __sfc: true,
      props: props,
      goToRoute: goToRoute,
      backRoute: backRoute,
      close: close,
      mode: mode,
      tooth: tooth,
      PathologyAndRestorationWizard: PathologyAndRestorationWizard
    };
  }
};