import camelize from '@/utils/camelize.js';

export default {
    escapeHtml(text) {
        if (typeof text != "string") {
            return '';
        }

        return text.replace(/&/g, '&amp;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;')
            .replace(/"/g, '&quot;')
            .replace(/'/g, '&apos;')
        ;
    },
    dasherize(text) {
        // convert fooBar to foo-bar
        return text.replace(/([A-Z])/g, (g) => `-${g[0].toLowerCase()}`);
    },
    camelize(text) {
        return camelize(text);
    },
    humanize(text) {
        const result = text.replace(/_/g, ' ');
        return result.charAt(0).toUpperCase() + result.slice(1);
    },
    capitalize(text) {
        return text.charAt(0).toUpperCase() + text.slice(1);
    },
};
