import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.values.js";
import TypeSelector from '@/components/patient/tooth/pathology-and-restoration/type-selector.vue';
import DetailsSelector from '@/components/patient/tooth/pathology-and-restoration/details-selector.vue';
import ZoneSelector from '@/components/patient/tooth/pathology-and-restoration/zone-selector.vue';
import SubscriptionBanner from "@/components/subscription-banner.vue";
import { PATHOLOGY_AND_RESTORATION_ACTIONS } from '@/config/pathologies-and-restorations.js';
export default {
  __name: 'pathology-and-restoration',
  props: {
    tooth: {
      required: true
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var _useNavigation = useNavigation(),
      goToRoute = _useNavigation.goToRoute,
      backRoute = _useNavigation.backRoute,
      toothRoute = _useNavigation.toothRoute;
    var close = function close() {
      goToRoute(backRoute.value);
    };
    useCallbackOnEscape(close);
    var route = useRoute();
    var _useI18n = useI18n(),
      t = _useI18n.t;

    // the view needs to remain a ref with a watcher
    // and mode must not directly be a computed property for route.name.
    // otherwise the mode gets changed too soon after saving values
    // and the respective storeScope gets set to patient/teeth/state/overview when calling endEditingTooth
    var view = ref(undefined);
    var routeView = computed(function () {
      return route.name;
    });
    watch(routeView, function (value) {
      view.value = value;
    }, {
      immediate: true
    });
    var mode = computed(function () {
      return view.value;
    });
    var tooth = computed(function () {
      return props.tooth;
    });
    var getTypeRoute = function getTypeRoute(typeName) {
      return toothRoute(tooth.value, route.name, {
        type_name: typeName || null
      });
    };
    var _usePathologyAndResto = usePathologyAndRestorationEditing({
        mode: mode,
        tooth: tooth,
        getTypeRoute: getTypeRoute
      }),
      baseRoute = _usePathologyAndResto.baseRoute,
      types = _usePathologyAndResto.types,
      details = _usePathologyAndResto.details,
      zones = _usePathologyAndResto.zones,
      actionsEnabled = _usePathologyAndResto.actionsEnabled,
      actionLabel = _usePathologyAndResto.actionLabel,
      waiterName = _usePathologyAndResto.waiterName,
      performAction = _usePathologyAndResto.performAction,
      initializePathologyAndRestorationEditing = _usePathologyAndResto.initializePathologyAndRestorationEditing;
    initializePathologyAndRestorationEditing();
    var actionNames = Object.values(PATHOLOGY_AND_RESTORATION_ACTIONS);
    var afterActionRoute = computed(function () {
      return toothRoute(tooth.value);
    });
    var actionClicked = function actionClicked(name) {
      performAction(name, afterActionRoute);
    };
    return {
      __sfc: true,
      props: props,
      goToRoute: goToRoute,
      backRoute: backRoute,
      toothRoute: toothRoute,
      close: close,
      route: route,
      t: t,
      view: view,
      routeView: routeView,
      mode: mode,
      tooth: tooth,
      getTypeRoute: getTypeRoute,
      baseRoute: baseRoute,
      types: types,
      details: details,
      zones: zones,
      actionsEnabled: actionsEnabled,
      actionLabel: actionLabel,
      waiterName: waiterName,
      performAction: performAction,
      initializePathologyAndRestorationEditing: initializePathologyAndRestorationEditing,
      actionNames: actionNames,
      afterActionRoute: afterActionRoute,
      actionClicked: actionClicked,
      TypeSelector: TypeSelector,
      DetailsSelector: DetailsSelector,
      ZoneSelector: ZoneSelector,
      SubscriptionBanner: SubscriptionBanner
    };
  }
};