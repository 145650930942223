import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.reverse.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
export var useChartTeethNavigation = function useChartTeethNavigation(_ref) {
  var teeth = _ref.teeth,
    validTeethIndexes = _ref.validTeethIndexes,
    tooth = _ref.tooth;
  var _useNavigation = useNavigation(),
    toothRoute = _useNavigation.toothRoute;
  var route = useRoute();
  var toothIndex = computed(function () {
    return teeth.value.findIndex(function (t) {
      return t.number == tooth.value.number;
    });
  });

  // these are used for manual navigation of next / previous teeth
  var previousTooth = computed(function () {
    var indexes = validTeethIndexes.value.slice().reverse();
    var previousIndex = indexes.find(function (n) {
      return n < toothIndex.value;
    });
    if (previousIndex == undefined) {
      // if there are no indexes smaller than this one, loop around to the other end
      previousIndex = indexes[0];
      // if there are no indexes at all, just stay on the same tooth
      if (previousIndex == undefined) {
        previousIndex = toothIndex.value;
      }
    }
    return teeth.value[previousIndex];
  });
  var nextTooth = computed(function () {
    var indexes = validTeethIndexes.value;
    var nextIndex = indexes.find(function (n) {
      return n > toothIndex.value;
    });
    if (nextIndex == undefined) {
      // if there are no indexes larger than this one, loop around to the start
      nextIndex = indexes[0];
      // if there are no indexes at all, just stay on the same tooth
      if (nextIndex == undefined) {
        nextIndex = toothIndex.value;
      }
    }
    return teeth.value[nextIndex];
  });
  var previousToothRoute = computed(function () {
    return toothRoute(previousTooth.value, route.name, {
      type: undefined,
      zone_name: undefined
    });
  });
  var nextToothRoute = computed(function () {
    return toothRoute(nextTooth.value, route.name, {
      type: undefined,
      zone_name: undefined
    });
  });
  return {
    toothIndex: toothIndex,
    previousToothRoute: previousToothRoute,
    nextToothRoute: nextToothRoute
  };
};