<template>
    <main data-view="chart" :data-mode="mode" :class="{ 'has-selected-tooth': selectedTooth }">
        <div class="header">
            <h1>{{ patient.full_name }}</h1>

            <mode-selector :current="mode" />

            <basic-periodontal-examination-trigger :patient-id="patient.id" v-if="sideSectionAvailable" />
        </div>

        <nav class="mouth" v-if="sideSectionAvailable">
            <ul>
                <navigation-link :to="chartRoute(mode, 'upper-jaw')" tag="li">
                    <svg-image
                        name="upper-jaw"
                        :title="t('upper', 'dentalChartVC.jaw')"
                        width="28"
                        height="28"
                    />
                </navigation-link>
                <navigation-link :to="chartRoute(mode, 'full-mouth')" tag="li">
                    <svg-image
                        name="jaws"
                        :title="t('fullMouth', 'dentalChartVC.jaw')"
                        width="28"
                        height="28"
                    />
                </navigation-link>
                <navigation-link :to="chartRoute(mode, 'lower-jaw')" tag="li">
                    <svg-image
                        name="lower-jaw"
                        :title="t('lower', 'dentalChartVC.jaw')"
                        width="28"
                        height="28"
                    />
                </navigation-link>
            </ul>
        </nav>

        <router-view
            :mode="mode"
            :teeth="teeth"
        />

        <rollback-panel v-if="overviewMode" />

        <chart-legend-panel />

        <periodontal-probing
            v-if="periodontalProbingActive"
            :teeth="teeth"
            :tooth="selectedTooth"
            :view="mouthView"
        />

        <pathology-and-restoration-panel
            v-if="pathologyActive || restorationActive"
            :teeth="teeth"
            :tooth="selectedTooth"
            :mode="mode"
        />

        <quickselect-panel
            v-if="quickSelectMode"
            :view="mouthView"
        />

        <subscription-banner />

    </main>
</template>

<script>
import { CHART_MODES, ERUPTION_STATES } from '@/config/teeth.js';

import ModeSelector from '@/components/patient/chart/mode-selector.vue';
import RollbackPanel from '@/components/patient/chart/rollback.vue';
import PeriodontalProbing from '@/components/patient/tooth/periodontal-probing.vue';
import ChartLegendPanel from '@/components/patient/chart/legend/panel.vue';
import QuickselectPanel from '@/components/patient/chart/quickselect.vue';
import PathologyAndRestorationPanel from '@/components/patient/chart/pathology-and-restoration.vue';
import SubscriptionBanner from "@/components/subscription-banner.vue";
import BasicPeriodontalExaminationTrigger from '@/components/patient/chart/basic-periodontal-examination-trigger.vue';

export default {
    components: {
        ModeSelector,
        BasicPeriodontalExaminationTrigger,
        RollbackPanel,
        PeriodontalProbing,
        ChartLegendPanel,
        QuickselectPanel,
        PathologyAndRestorationPanel,
        SubscriptionBanner
    },

    props: {
        patient: { required: true },
        mode: { required: true }
    },
    computed: {
        overviewMode() {
            return this.mode == CHART_MODES.OVERVIEW;
        },
        quickSelectMode() {
            return this.mode == CHART_MODES.QUICKSELECT;
        },
        periodontalProbingMode() {
            return this.mode == CHART_MODES.PERIODONTAL_PROBING;
        },
        pathologyMode() {
            return this.mode == CHART_MODES.PATHOLOGY;
        },
        restorationMode() {
            return this.mode == CHART_MODES.RESTORATION;
        },
        periodontalProbingActive() {
            return this.periodontalProbingMode && this.selectedTooth;
        },
        pathologyActive() {
            return this.pathologyMode && this.selectedTooth;
        },
        restorationActive() {
            return this.restorationMode && this.selectedTooth;
        },
        selectedTooth() {
            return this.$store.getters['patient/teeth/toothBySlug'](this.$route.params.tooth_slug);
        },
        mouthView() {
            return this.$route.meta['mouth-view'];
        },

        teethByJaw() {
            return {
                'upper-jaw': this.$store.getters['patient/teeth/upperJaw'],
                'lower-jaw': this.$store.getters['patient/teeth/lowerJaw'],
            };
        },

        teeth() {
            if (this.mouthView == 'upper-jaw' || this.mouthView == 'lower-jaw') {
                return this.prepareTeeth(this.mouthView);
            } else {
                return this.prepareTeeth('upper-jaw').concat(this.prepareTeeth('lower-jaw'));
            }
        },

        teethEruptionStatesByJaw() {
            return Object.keys(this.teethByJaw).reduce((eruptionStatesByJaw, jaw) => {
                eruptionStatesByJaw[jaw] = this.teethByJaw[jaw].map(tooth => this.toothEruptionState(tooth.number));
                return eruptionStatesByJaw;
            }, {});
        },

        sideSectionAvailable() {
            // BPE trigger and mouth navigation items need to be hidden
            // if the sidebar is open, because it causes overlap issues.

            // also, jaw navigation must be disabled in quickselect mode because some teeth may be selected
            // and changing the jaw would cause problems
            if (this.periodontalProbingActive || this.pathologyActive || this.restorationActive || this.quickSelectMode ) {
                return false;
            }

            return true;
        }
    },

    watch: {
        selectedTooth() {
            this.ensureValidSelectedTooth();
        }
    },
    created() {
        this.ensureValidSelectedTooth();
        this.$store.dispatch('audio/markSoundsAsNeeded');
    },

    methods: {
        prepareTeeth(jaw) {
            const teeth = this.teethByJaw[jaw];
            const eruptionStates = this.teethEruptionStatesByJaw[jaw];

            return teeth.map((tooth, index) => {
                return Object.assign({}, tooth, {
                    selected: this.isToothSelected(tooth),
                    surroundedByEruptedTeeth: this.isToothSurroundedByEruptedTeeth(index, eruptionStates)
                });
            });
        },
        isToothSelected(tooth) {
            if (
                this.selectedTooth
                &&
                this.selectedTooth.number == tooth.number
                &&
                (this.periodontalProbingMode || this.pathologyMode || this.restorationMode)
            ) {
                return true;
            }
            return false;
        },
        toothEruptionState(toothNumber) {
            return this.$store.getters['patient/teeth/state/eruption'](toothNumber);
        },

        isToothSurroundedByEruptedTeeth(indexInRow, eruptionStatesInRow) {
            // returns ture if there is at least one erupted tooth somewhere both to the left and to the right in this row.
            // this is used to differentiate between non-erupted teeth at the ends of a jaw and somehwere in the middle
            const statesBefore = eruptionStatesInRow.slice(0, indexInRow);
            const statesAfter = eruptionStatesInRow.slice(indexInRow + 1);

            const matcherFunction = element => element == ERUPTION_STATES.ERUPTED;
            return statesBefore.some(matcherFunction) && statesAfter.some(matcherFunction);
        },

        ensureValidSelectedTooth() {
            if (
                this.periodontalProbingMode
                &&
                this.$route.params.tooth_slug
                &&
                (
                    (!this.selectedTooth)
                    ||
                    !this.teeth.some(tooth => tooth.number == this.selectedTooth.number)
                )
            ) {
                const mouthRoute = this.chartRoute(this.mode, this.mouthView, { tooth_slug: undefined, zone_name: undefined });
                this.goToRoute(mouthRoute, { replace: true });
            }
        }

    }
};
</script>
<style lang="scss">
@import "~@/assets/stylesheets/views/patient/chart";
</style>
