<template>
    <div class="mode-selector">
        <ul class="menu">
            <navigation-link
                v-for="mode in modes"
                :key="mode"
                tag="li"
                :to="route(mode)"
                replace
                :exact="overviewMode(mode)"
            >
                {{ label(mode) }}
            </navigation-link>
        </ul>

    </div>
</template>
<script>
import { CHART_MODES } from '@/config/teeth.js';

export default {
    mixins: [ ],
    props: {
        current: { required: true },
    },
    data() {
        return {

        };
    },

    computed:
    {
        routeName() {
            return this.$route.name;
        },
        modes() {
            return this.$store.getters['chart/selectableModes'];
        }
    },

    watch: {
    },
    methods:
    {
        label(mode) {
            return this.t(this.$options.filters.camelize(mode), 'dentalChartVC.examination');
        },

        route(mode) {
            const pathologyAndRestorationModes = [CHART_MODES.PATHOLOGY, CHART_MODES.RESTORATION];
            const pathologyOrRestoration = pathologyAndRestorationModes.includes(mode);
            const currentlyInPathologyOrRestoration = pathologyAndRestorationModes.includes(this.$route.meta['mode']);
            const sameAsCurrentMode = mode == this.$route.meta['mode'];

            // when switching between pathology and restoration, preserve the selected tooth
            // but allow closing it if clicking on the same mode again
            const preserveCurrentTooth = pathologyOrRestoration && currentlyInPathologyOrRestoration && !sameAsCurrentMode;
            const toothSlug = (preserveCurrentTooth) ? this.$route.params.tooth_slug : undefined;

            return this.chartRoute(mode, this.$route.meta['mouth-view'], {
                tooth_slug: toothSlug,
                zone_name: undefined,
                type_name: undefined
            });
        },

        overviewMode(mode) {
            return mode == CHART_MODES.OVERVIEW;
        }
    },

};
</script>
