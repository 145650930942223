import camelize from "@/utils/camelize.js";
import clone from "@/utils/clone.js";
export var usePathologyAndRestorationWizardTypeStep = function usePathologyAndRestorationWizardTypeStep(_ref) {
  var mode = _ref.mode,
    selectedType = _ref.selectedType,
    types = _ref.types;
  var _useI18n = useI18n(),
    t = _useI18n.t;
  var typeStepIsOpen = ref(!selectedType.value);
  var openTypeStep = function openTypeStep() {
    typeStepIsOpen.value = true;
  };
  var closeTypeStep = function closeTypeStep() {
    typeStepIsOpen.value = false;
  };
  watch(selectedType, function (value) {
    if (value) {
      // close type selector after setting or changing type value
      closeTypeStep();
    } else {
      // open type selector after clearing selected type (e.g., switching mode)
      openTypeStep();
    }
  });
  var typeStepLabel = computed(function () {
    return t(camelize(mode.value), 'dentalChartVC.examination');
  });
  var typeStepText = computed(function () {
    return selectedType.value ? selectedType.value.label : null;
  });
  var clonedTypes = computed(function () {
    return clone(types.value);
  });
  return {
    typeStepIsOpen: typeStepIsOpen,
    openTypeStep: openTypeStep,
    typeStepLabel: typeStepLabel,
    typeStepText: typeStepText,
    clonedTypes: clonedTypes
  };
};