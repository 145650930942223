import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.array.reverse.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.includes.js";
import AudioButton from '@/components/patient/tooth/overview/periodontal/audio-button.vue';
import ZonePad from '@/components/patient/tooth/overview/periodontal/zone-pad.vue';
import DepthSelector from '@/components/patient/tooth/overview/periodontal/depth-selector.vue';
import MarginSelector from '@/components/patient/tooth/overview/periodontal/margin-selector.vue';
import IssueSelector from '@/components/patient/tooth/overview/periodontal/issue-selector.vue';
import FurcationSelector from '@/components/patient/tooth/overview/periodontal/furcation-selector.vue';
import MobilitySelector from '@/components/patient/tooth/overview/periodontal/mobility-selector.vue';
import SequentialModeSwitch from '@/components/patient/tooth/overview/periodontal/sequential-mode-switch.vue';
import TeethNavigation from '@/components/patient/chart/side-panel/teeth-navigation.vue';
import SubscriptionBanner from "@/components/subscription-banner.vue";
import freeze from '@/utils/freeze.js';
import { TOOTH_SETS, FACES, JAWS } from '@/config/teeth.js';
import { WAITERS } from '@/config/waiters.js';
export default {
  __name: 'periodontal-probing',
  props: {
    teeth: {
      required: false
    },
    tooth: {
      required: true
    },
    view: {
      required: true
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var teeth = computed(function () {
      return props.teeth;
    });
    var tooth = computed(function () {
      return props.tooth;
    });
    var mode = computed(function () {
      return props.view == 'tooth' ? 'single-tooth' : 'chart';
    });
    var singleToothMode = computed(function () {
      return mode.value == 'single-tooth';
    });
    var chartMode = computed(function () {
      return mode.value == 'chart';
    });
    var fullMouthView = computed(function () {
      return chartMode.value && props.view == 'full-mouth';
    });
    var route = useRoute();
    var store = useStore();
    var _useNavigation = useNavigation(),
      goToRoute = _useNavigation.goToRoute,
      backRoute = _useNavigation.backRoute,
      toothRoute = _useNavigation.toothRoute,
      chartRouteName = _useNavigation.chartRouteName;
    var close = function close() {
      goToRoute(backRoute.value);
    };
    useCallbackOnEscape(close);
    var waiterName = WAITERS.SAVING_PERIODONTAL_STATE;
    var MEASUREMENTS = freeze({
      DEPTH: "depth",
      MARGIN: "margin"
    });
    var measurements = [MEASUREMENTS.DEPTH, MEASUREMENTS.MARGIN];
    var selectedZoneName = ref(undefined);
    var selectedMeasurement = ref(MEASUREMENTS.DEPTH);
    var toothNumber = computed(function () {
      return tooth.value.number;
    });
    var zoneNames = computed(function () {
      return store.getters['patient/teeth/state/periodontal/zoneNames'](toothNumber.value);
    });
    var routeZoneName = computed(function () {
      return route.params.zone_name;
    });
    var zone = computed(function () {
      return store.getters['patient/teeth/state/periodontal/zone'](toothNumber.value, selectedZoneName.value);
    });
    var isFurcationPossible = computed(function () {
      return store.getters['patient/teeth/state/periodontal/isFurcationPossible'](tooth.value.number);
    });
    var editableDepth = computed({
      get: function get() {
        return zone.value.depth;
      },
      set: function set(value) {
        return setMeasurement(MEASUREMENTS.DEPTH, value);
      }
    });
    var editableMargin = computed({
      get: function get() {
        return zone.value.margin;
      },
      set: function set(value) {
        return setMeasurement(MEASUREMENTS.MARGIN, value);
      }
    });
    var editableIssues = computed({
      get: function get() {
        return zone.value.issues;
      },
      set: function set(issueValues) {
        return setIssues(issueValues);
      }
    });
    var editableFurcation = computed({
      get: function get() {
        return store.getters['patient/teeth/state/periodontal/furcation'](toothNumber.value);
      },
      set: function set(value) {
        return setFurcation(value);
      }
    });
    var editableMobility = computed({
      get: function get() {
        return store.getters['patient/teeth/state/periodontal/mobility'](toothNumber.value);
      },
      set: function set(value) {
        return store.dispatch('patient/teeth/state/periodontal/setEditableMobility', {
          toothNumber: toothNumber.value,
          value: value
        });
      }
    });
    var isToothBeingEdited = function isToothBeingEdited() {
      return store.getters['patient/teeth/state/periodontal/isToothBeingEdited'];
    };
    var startEditingTooth = function startEditingTooth() {
      store.dispatch('patient/teeth/state/periodontal/startEditingTooth', {
        toothNumber: toothNumber.value
      });
    };
    var endEditingTooth = function endEditingTooth() {
      var task = chartMode.value ? save() : Promise.resolve();
      return task.then(function () {
        return store.dispatch('patient/teeth/state/periodontal/endEditingTooth');
      });
    };
    var selectRouteZone = function selectRouteZone() {
      // this corrects unrecognized zone names in URL (first zone gets selected)
      // and also toggles palatal / lingual pairs for upper / lower jaw
      var validZoneName = store.getters['patient/teeth/state/periodontal/correctZoneName'](toothNumber.value, routeZoneName.value, {
        chartMode: chartMode.value
      });
      if (validZoneName != routeZoneName.value) {
        goToRoute(zoneRoute(validZoneName), {
          replace: true
        });
      } else {
        selectedZoneName.value = validZoneName;
      }
    };
    var zoneRoute = function zoneRoute(zoneName, toothInstance) {
      if (!toothInstance) {
        toothInstance = tooth.value;
      }
      var routeName = singleToothMode.value ? 'periodontal-probing' : chartRouteName('periodontal-probing', props.view);
      return toothRoute(toothInstance, routeName, {
        zone_name: zoneName
      });
    };
    var setMeasurement = function setMeasurement(type, value) {
      return store.dispatch('patient/teeth/state/periodontal/setEditableMeasurement', {
        toothNumber: toothNumber.value,
        zoneName: selectedZoneName.value,
        type: type,
        value: value
      }).then(function () {
        var absoluteValue = Math.abs(value);
        if (absoluteValue > 0 && isAudioEnabled.value) {
          var audioName = absoluteValue > 12 ? 'greater-12' : absoluteValue;
          store.dispatch('audio/play', [audioName]);
        }
        if (chartMode.value && sequentialAutoMode.value) {
          return goToNextSequentialStep();
        }
      });
    };
    var setIssues = function setIssues(issueValues) {
      var addedIssueNames = issueValues.filter(function (value) {
        return !(editableIssues.value || []).includes(value);
      }).map(function (value) {
        return store.getters['patient/teeth/state/periodontal/issueName'](value);
      });
      return store.dispatch('patient/teeth/state/periodontal/setEditableIssues', {
        toothNumber: toothNumber.value,
        zoneName: selectedZoneName.value,
        issueValues: issueValues
      }).then(function () {
        if (addedIssueNames.length > 0 && isAudioEnabled.value) {
          store.dispatch('audio/play', addedIssueNames);
        }
      });
    };
    var setFurcation = function setFurcation(value) {
      return store.dispatch('patient/teeth/state/periodontal/setEditableFurcation', {
        toothNumber: toothNumber.value,
        value: value
      }).then(function () {
        if (value && isAudioEnabled.value) {
          store.dispatch('audio/play', ['furcation', 'grade-' + value]);
        }
      });
    };
    var save = function save() {
      return store.dispatch('patient/teeth/state/periodontal/saveEditableToothState');
    };
    var submit = function submit() {
      // only save in single tooth mode,
      // because in chart mode saving will be triggered automatically on route change
      if (singleToothMode.value) {
        return save().then(function () {
          return close();
        });
      } else {
        goToRoute(nextToothRoute.value, {
          replace: true
        });
      }
    };
    var initialize = function initialize() {
      zoneAudioAllowed.value = false;
      var task = isToothBeingEdited() ? endEditingTooth() : Promise.resolve();
      task.then(function () {
        return startEditingTooth();
      });
      selectRouteZone();
      if (chartMode.value && isAudioEnabled.value) {
        playToothAudio();
      }
      nextTick(function () {
        zoneAudioAllowed.value = true;
      });
    };
    watch(routeZoneName, function () {
      selectRouteZone();
    });
    onBeforeUnmount(function () {
      endEditingTooth();
    });
    watch(toothNumber, function () {
      initialize();
    });

    // tooth navigation in chart mode
    var validTeethIndexes = computed(function () {
      // all teeth are valid except missing and unerupted ones
      // NOTE: if this logic gets changed, also change it in tooth trigger component,
      // so that invalid teeth cannot get selected
      return teeth.value.reduce(function (array, tooth, index) {
        if (store.getters['patient/teeth/state/isToothPresent'](tooth.number)) {
          array.push(index);
        }
        return array;
      }, []);
    });
    var _useChartTeethNavigat = useChartTeethNavigation({
        teeth: teeth,
        validTeethIndexes: validTeethIndexes,
        tooth: tooth
      }),
      toothIndex = _useChartTeethNavigat.toothIndex,
      previousToothRoute = _useChartTeethNavigat.previousToothRoute,
      nextToothRoute = _useChartTeethNavigat.nextToothRoute;

    // audio handling
    var zoneAudioAllowed = ref(false);
    var isAudioEnabled = computed(function () {
      return store.state.patient.teeth.state.periodontal.audioEnabled;
    });
    var isAudioAvailable = computed(function () {
      return chartMode.value ? !sequentialAutoMode.value : true;
    });
    watch(selectedZoneName, function (zoneName) {
      if (zoneAudioAllowed.value // this prevents triggering zone audio on first load or when changing tooth + zone
      && isAudioEnabled.value) {
        store.dispatch('audio/play', [zoneName]);
      }
    });
    var playToothAudio = function playToothAudio() {
      // :TODO: add audio files for USA labels (#37)
      if (store.getters['settings/isoNotationActive'] && tooth.value.set == TOOTH_SETS.PERMANENT) {
        store.dispatch('audio/play', [tooth.value.type + '-tooth', tooth.value.quadrant + '-' + tooth.value.position]);
      }
    };
    var disableAudio = function disableAudio() {
      store.dispatch('patient/teeth/state/periodontal/disableAudio');
    };

    // sequential auto mode
    var SEQUENTIAL_AUTO_MODE_LOCAL_STORAGE_KEY = 'sequential-periodontal-probing-auto-mode';
    var internalSequentialAutoMode = ref(localStorage.getItem(SEQUENTIAL_AUTO_MODE_LOCAL_STORAGE_KEY) == '1');
    var sequentialAutoMode = computed({
      get: function get() {
        return internalSequentialAutoMode.value;
      },
      set: function set(value) {
        localStorage.setItem(SEQUENTIAL_AUTO_MODE_LOCAL_STORAGE_KEY, value ? '1' : '0');
        internalSequentialAutoMode.value = value;
      }
    });
    watch(sequentialAutoMode, function (on) {
      if (on) {
        disableAudio();
      }
    });
    if (sequentialAutoMode.value) {
      disableAudio();
    }
    var currentJaw = computed(function () {
      return tooth.value.jaw;
    });
    var otherJaw = computed(function () {
      return currentJaw.value == JAWS.UPPER ? JAWS.LOWER : JAWS.UPPER;
    });
    var validTeethIndexesInCurrentJaw = computed(function () {
      return validTeethIndexes.value.filter(function (index) {
        return teeth.value[index].jaw == currentJaw.value;
      });
    });
    var validTeethIndexesInOtherJaw = computed(function () {
      return validTeethIndexes.value.filter(function (index) {
        return teeth.value[index].jaw == otherJaw.value;
      });
    });
    var currentZoneFace = computed(function () {
      return selectedZoneName.value.includes("buccal") ? FACES.BUCCAL : FACES.PALATAL_OR_LINGUAL;
    });
    var otherZoneFace = computed(function () {
      return currentZoneFace.value == FACES.BUCCAL ? FACES.PALATAL_OR_LINGUAL : FACES.BUCCAL;
    });
    var goToNextSequentialStep = function goToNextSequentialStep() {
      // navigates to either next zone or next tooth + zone

      // the order is as follows:
      // 1) starting from quadrant 1, all buccal zones for each tooth,
      // up to the end of the upper jaw at the end of quadrant 2
      // 2) then back through quadrants 2 and 1 using all palatal zones in reverse order

      // 3) then the same for lower jaw (quadrants 4 and 3 buccal zones, and back quadrants 3 / 4 lingual zones)
      // 4) at the very end, go back to 1)

      // in single jaw mode, only the current jaw is traversed

      // see whether there is a next zone in the current zone row for the current tooth
      var nextZoneNameInRow = nextSequentialZoneNameInCurrentRow.value;
      if (nextZoneNameInRow) {
        // go to next zone in the row
        return goToRoute(zoneRoute(nextZoneNameInRow));
      }

      // no next zone found in current row, must switch to a new tooth and zone
      // try next tooth in the same jaw in the same direction
      var nextToothAndZoneName = nextSequentialToothAndZoneNameInCurrentJaw.value;
      if (nextToothAndZoneName) {
        return goToRoute(zoneRoute(nextToothAndZoneName.zoneName, nextToothAndZoneName.tooth));
      }

      // no more teeth to go in the current jaw in the current direction

      // if in buccal face, go to palatal/lingual face of same toooth
      // (reversing at the end of the jaw's first pass)
      if (currentZoneFace.value == FACES.BUCCAL) {
        var nextZoneNameInOtherRow = nextSequentialZoneNameInOtherRow.value;
        return goToRoute(zoneRoute(nextZoneNameInOtherRow));
      }

      // if in palatal/lingual face,
      // go to start of other jaw if in full mouth view
      // or go to start of same jaw in single jaw view (restart whole sequence)
      if (currentZoneFace.value == FACES.PALATAL_OR_LINGUAL) {
        var nextTooth = nextSequentialToothInNextJaw.value;
        return goToRoute(toothRoute(nextTooth, route.name, {
          zone_name: undefined
        }));
      }
    };
    var nextSequentialZoneNameInCurrentRow = computed(function () {
      var zoneNameRows = store.getters['patient/teeth/state/periodontal/zoneNameRows'](toothNumber.value);
      var currentRow = zoneNameRows[currentZoneFace.value];
      if (!currentRow) {
        return undefined;
      }
      var currentZoneIndexInRow = currentRow.findIndex(function (zoneName) {
        return zoneName == selectedZoneName.value;
      });
      if (currentZoneIndexInRow < 0) {
        return undefined;
      }
      var nextOffset = currentZoneFace.value == FACES.BUCCAL ? 1 : -1;
      return currentRow[currentZoneIndexInRow + nextOffset];
    });
    var nextSequentialZoneNameInOtherRow = computed(function () {
      var zoneNameRows = store.getters['patient/teeth/state/periodontal/zoneNameRows'](toothNumber.value);
      var otherRow = zoneNameRows[otherZoneFace.value];
      return otherZoneFace.value == FACES.PALATAL_OR_LINGUAL ? otherRow[otherRow.length - 1] : otherRow[0];
    });
    var nextSequentialToothAndZoneNameInCurrentJaw = computed(function () {
      var currentlyInBuccalZoneRow = currentZoneFace.value == FACES.BUCCAL;
      var teethIndexSequenceInCurrentJaw = currentlyInBuccalZoneRow ? validTeethIndexesInCurrentJaw.value : validTeethIndexesInCurrentJaw.value.slice().reverse();
      var currentToothIndex = toothIndex.value;
      var currentPositionInSequence = teethIndexSequenceInCurrentJaw.findIndex(function (index) {
        return index == currentToothIndex;
      });
      if (currentPositionInSequence < 0) {
        return undefined;
      }
      var nextToothIndex = teethIndexSequenceInCurrentJaw[currentPositionInSequence + 1];
      if (nextToothIndex === undefined) {
        // end of jaw reached
        return undefined;
      }
      var nextTooth = teeth.value[nextToothIndex];
      var zoneNameRowsByFace = store.getters['patient/teeth/state/periodontal/zoneNameRows'](nextTooth.number);
      var initialZoneRow = zoneNameRowsByFace[currentZoneFace.value];
      var initialZoneName = currentlyInBuccalZoneRow ? initialZoneRow[0] : initialZoneRow[initialZoneRow.length - 1];
      return {
        tooth: nextTooth,
        zoneName: initialZoneName
      };
    });
    var nextSequentialToothInNextJaw = computed(function () {
      var validTeethIndexesInTargetJaw;
      if (fullMouthView.value) {
        // in full mouth view, go to the start of the other jaw,
        // unless an edge case occurs where there are no valid teeth in the other jaw - then stay in the same jaw
        validTeethIndexesInTargetJaw = validTeethIndexesInOtherJaw.value.length > 0 ? validTeethIndexesInOtherJaw.value : validTeethIndexesInCurrentJaw.value;
      } else {
        // in single jaw view, stay in the same jaw
        validTeethIndexesInTargetJaw = validTeethIndexesInCurrentJaw.value;
      }
      return teeth.value[validTeethIndexesInTargetJaw[0]];
    });
    initialize();
    return {
      __sfc: true,
      props: props,
      teeth: teeth,
      tooth: tooth,
      mode: mode,
      singleToothMode: singleToothMode,
      chartMode: chartMode,
      fullMouthView: fullMouthView,
      route: route,
      store: store,
      goToRoute: goToRoute,
      backRoute: backRoute,
      toothRoute: toothRoute,
      chartRouteName: chartRouteName,
      close: close,
      waiterName: waiterName,
      MEASUREMENTS: MEASUREMENTS,
      measurements: measurements,
      selectedZoneName: selectedZoneName,
      selectedMeasurement: selectedMeasurement,
      toothNumber: toothNumber,
      zoneNames: zoneNames,
      routeZoneName: routeZoneName,
      zone: zone,
      isFurcationPossible: isFurcationPossible,
      editableDepth: editableDepth,
      editableMargin: editableMargin,
      editableIssues: editableIssues,
      editableFurcation: editableFurcation,
      editableMobility: editableMobility,
      isToothBeingEdited: isToothBeingEdited,
      startEditingTooth: startEditingTooth,
      endEditingTooth: endEditingTooth,
      selectRouteZone: selectRouteZone,
      zoneRoute: zoneRoute,
      setMeasurement: setMeasurement,
      setIssues: setIssues,
      setFurcation: setFurcation,
      save: save,
      submit: submit,
      initialize: initialize,
      validTeethIndexes: validTeethIndexes,
      toothIndex: toothIndex,
      previousToothRoute: previousToothRoute,
      nextToothRoute: nextToothRoute,
      zoneAudioAllowed: zoneAudioAllowed,
      isAudioEnabled: isAudioEnabled,
      isAudioAvailable: isAudioAvailable,
      playToothAudio: playToothAudio,
      disableAudio: disableAudio,
      SEQUENTIAL_AUTO_MODE_LOCAL_STORAGE_KEY: SEQUENTIAL_AUTO_MODE_LOCAL_STORAGE_KEY,
      internalSequentialAutoMode: internalSequentialAutoMode,
      sequentialAutoMode: sequentialAutoMode,
      currentJaw: currentJaw,
      otherJaw: otherJaw,
      validTeethIndexesInCurrentJaw: validTeethIndexesInCurrentJaw,
      validTeethIndexesInOtherJaw: validTeethIndexesInOtherJaw,
      currentZoneFace: currentZoneFace,
      otherZoneFace: otherZoneFace,
      goToNextSequentialStep: goToNextSequentialStep,
      nextSequentialZoneNameInCurrentRow: nextSequentialZoneNameInCurrentRow,
      nextSequentialZoneNameInOtherRow: nextSequentialZoneNameInOtherRow,
      nextSequentialToothAndZoneNameInCurrentJaw: nextSequentialToothAndZoneNameInCurrentJaw,
      nextSequentialToothInNextJaw: nextSequentialToothInNextJaw,
      AudioButton: AudioButton,
      ZonePad: ZonePad,
      DepthSelector: DepthSelector,
      MarginSelector: MarginSelector,
      IssueSelector: IssueSelector,
      FurcationSelector: FurcationSelector,
      MobilitySelector: MobilitySelector,
      SequentialModeSwitch: SequentialModeSwitch,
      TeethNavigation: TeethNavigation,
      SubscriptionBanner: SubscriptionBanner
    };
  }
};