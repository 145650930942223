export default {
  __name: 'wizard-step',
  props: {
    type: {
      required: true
    },
    name: {
      required: true
    },
    label: {
      required: true
    },
    text: {
      required: true
    },
    isOpen: {
      required: false
    }
  },
  emits: ["reopen"],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var props = __props;
    var reopen = function reopen() {
      emit("reopen");
    };
    var state = computed(function () {
      return props.isOpen ? "open" : "closed";
    });
    return {
      __sfc: true,
      props: props,
      emit: emit,
      reopen: reopen,
      state: state
    };
  }
};