import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.iterator.js";
import DetailOptions from '@/components/patient/pathology-and-restoration/detail-options.vue';
export default {
  __name: 'details-selector',
  props: {
    value: {
      required: true
    }
  },
  emits: ["input"],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var props = __props;
    var details = computed(function () {
      return props.value;
    });
    var emitInput = function emitInput(_ref2) {
      var detailName = _ref2.detailName,
        values = _ref2.values;
      emit('input', {
        detailName: detailName,
        values: values
      });
    };
    return {
      __sfc: true,
      props: props,
      details: details,
      emit: emit,
      emitInput: emitInput,
      DetailOptions: DetailOptions
    };
  }
};