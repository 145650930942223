import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.string.replace.js";
import { CHART_MODES } from '@/config/teeth.js';
export var useNavigation = function useNavigation() {
  var store = useStore();
  var router = useRouter();
  var route = useRoute();

  // duplicated in mixins/navigation.js
  var chartRouteName = function chartRouteName(chartMode, mouthView) {
    if ([CHART_MODES.PERIODONTAL_PROBING, CHART_MODES.QUICKSELECT, CHART_MODES.PATHOLOGY, CHART_MODES.RESTORATION].includes(chartMode)) {
      return chartMode + '-' + mouthView;
    }
    return mouthView;
  };

  // duplicated in mixins/navigation.js
  var chartRoute = function chartRoute(chartMode, mouthView, extraParams) {
    var name = chartRouteName(chartMode, mouthView);
    return {
      name: name,
      params: Object.assign({}, route.params, extraParams || {})
    };
  };

  // duplicated in mixins/navigation.js
  var backRoute = computed(function () {
    var back = route.meta.back;
    if (typeof back == 'function') {
      return back(route, chartRouteName);
    }
    return back;
  });

  // duplicated in mixins/navigation.js
  var toothRoute = function toothRoute(tooth, name, extraParams) {
    var routeName = name ? name : 'tooth';
    // can only be called from within a route that has patient_id in its params
    return {
      name: routeName,
      params: Object.assign({}, route.params, {
        tooth_slug: tooth.slug
      }, extraParams || {})
    };
  };

  // duplicated in mixins/navigation.js
  var goToRoute = function goToRoute(route, options) {
    var method = options && options.replace ? 'replace' : 'push';
    return router[method](route)["catch"](function (error) {
      if (error && error.name != 'NavigationDuplicated') {
        throw error;
      }
    });
  };

  // duplicated in mixins/navigation.js
  var ensureTeethStateModifiability = function ensureTeethStateModifiability() {
    if (store.getters['patient/teeth/state/isModifiable']) {
      return Promise.resolve();
    } else {
      store.dispatch('patient/teeth/state/openRollbackWarning');
      return Promise.reject();
    }
  };
  return {
    backRoute: backRoute,
    toothRoute: toothRoute,
    chartRouteName: chartRouteName,
    chartRoute: chartRoute,
    goToRoute: goToRoute,
    ensureTeethStateModifiability: ensureTeethStateModifiability
  };
};