import { render, staticRenderFns } from "./periodontal-probing.vue?vue&type=template&id=a3574270"
import script from "./periodontal-probing.vue?vue&type=script&setup=true&lang=js"
export * from "./periodontal-probing.vue?vue&type=script&setup=true&lang=js"
import style0 from "./periodontal-probing.vue?vue&type=style&index=0&id=a3574270&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports